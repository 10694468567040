<template>
  <el-dialog class="operation_dialog" v-model="dialogVisible" :title="dialogtitle" :close-on-click-modal="false"
    width="600px">
    <div class="pr-20 pl-20" v-loading="state.loding" :element-loading-text="config.loading.text"
      :element-loading-background="config.loading.background">
      <div class="input_box" style="margin-right: 0px;margin-bottom: 20px;">
        <el-input v-model="state.searchname" :maxlength="config.inputMaxlength" show-word-limit placeholder="输入成员名称搜索"
          :validate-event="false" />
      </div>
      <div class="itembox">
        <div class="item dp-f ai-c pt-r cu-p" v-for="(el, ind) in showData" :key="ind" @click="el.isCheck=!el.isCheck">
          <img src="@/assets/img/role/peopleIcon.png" class="w-34 h-34 mr-20" v-if="el.type==0" />
          <img src="@/assets/img/role/company.png" class="w-34 h-34 mr-20" v-else-if="el.type==1" />
          <div class="mr-20">{{ el.type == 0 ? el.name : el.name + (el.transactorPost?("-" + el.transactorPost):"") +  " - " + el.transactorName }}</div>
          <div>{{ el.phone }}</div>
          <img class="checkmark" src="@/assets/img/role/checkmark.png" v-if="el.isCheck">
        </div>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer dp-f fd-rr">
        <oabutton class="searcML" width='120' height='40' title="确定" CSStype=2
          @buttonclick="dialogloading = true, saveData()" v-model:loading="dialogloading"/>
        <oabutton class="searcML" width='120' height='40' title="取消" CSStype=3 @buttonclick="dialogVisible = false"/>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { reactive, ref, unref, nextTick, defineEmits, computed } from 'vue'
import { httpToken } from "@/utils/request";
import qs from "qs";
import { handleMessage } from "@/utils/server/confirm";
const dialogloading = ref(false);// 弹框按钮loading 显示
const dialogtitle = ref();
const dialogVisible = ref(false)//弹框显示
const emit = defineEmits(['Emit'])
const id = ref(0);//操作的id
const state = reactive({
  searchname: '',
  roleData: [],
})
// 保存数据
const saveData=()=>{
  emit('Emit',state.roleData.filter(function (item) {
    return item.isCheck==true
  }))
  dialogVisible.value = false
}
const showData = computed(() => {
  return state.roleData.filter(function (item) {
    return item.name.includes(state.searchname) || (item.transactorName && item.transactorName.includes(state.searchname)) || (item.transactorPost && item.transactorPost.includes(state.searchname))
  });
})
const selectAllUserByRoleId = (isup = false) => {
  let json = [
    // { column: "type", type: 'eq',value:0 },// 0：个人，1：公司
    { column: "create_date", type: 'orderByDesc' }
  ];
  httpToken({
    method: "post",
    url: '/admin/addressbook/all',
    data: qs.stringify({
      paramData: JSON.stringify(json),
    }),
  }).then((res) => {
    state.roleData = res.data.map(item=>{
      return {...item,isCheck:false}
    })
    dialogVisible.value = true
  })
}
const show = ((imgurl) => {
  state.searchname = ''
  state.roleData = []
  dialogloading.value=false
  dialogtitle.value = '通讯录添加'
  selectAllUserByRoleId()
})
defineExpose({
  show
});
</script>
<style lang="scss" scoped>
@import "@/styles/general/element.scss"; //element 样式重置
.itembox {
  max-height: 300px;
  overflow: auto;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;

  .item {
    padding: 0 10px;
    height: 46px;

    .checkmark {
      position: absolute;
      right: 16px;
    }
  }

  .item:hover {
    background-color: #f7f8f8;
  }

  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 5px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }

  &::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    //background-color: #8c8c8c;
    background-color: rgba(0, 0, 0, 0.25);
  }

  &::-webkit-scrollbar-track {
    background-color: #f6f6f6;
  }

  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-track {
    border: 0;
  }
}</style>